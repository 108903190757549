<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" >
    <v-row dense>
      <v-col cols="8">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Paciente" @change="updatePacienteData" ></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-text-field dense outlined label="Data da Requisição" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field dense outlined label="Data Necessidade Entrega" disabled v-model="dataImplantacao" type="date" ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-card-title class="blod_color">Itens Requisitados:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table dense :loading="isLoadingPacientes" :headers="ItemPacientelHeaders" :items="filterPaciente" item-value="id" show-select v-model="selectedPacienteItems" class="elevation-1" :items-per-page="5"  >
        <template v-slot:[`item.nome_esterilizavel`]="{ item }">
          {{ item.nome_esterilizavel }}
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          {{ item.quantidade }}
        </template>
        <template v-slot:[`item.qtdMarcada`]="{ item }">
          <span>{{ item.qtdMarcada !== null && item.qtdMarcada !== undefined ? item.qtdMarcada : 0 }}</span>
        </template>
    </v-data-table>
      </v-col>
    </v-row>

    <!-- Esterilizáveis -->
    <v-row dense>
      <v-card-title class="blod_color">Esterilizáveis Disponíveis:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table dense :loading="isLoadingEsterilizavel" :headers="esterelizavelHeaders" :items="esterelizavel" item-value="id" show-select v-model="selectedEsterelizaveis" class="elevation-1" @input="compareItems" :items-per-page="5" >
        <template v-slot:[`item.data_validade_esterilizacao`]="{ item }">
            <span>{{ item.data_validade_esterilizacao | formatDatabr }}</span>
        </template>
      </v-data-table>
      </v-col>
    </v-row>

    <v-row class="justify-end mt-0" dense>
  <v-btn class="mr-4 elevation-0" text @click="atualizarDados" :loading="isLoadingDados">
    <v-icon left>mdi-refresh</v-icon> Atualizar
  </v-btn>
  <v-col class="text-end">
    <v-tooltip top :disabled="isSaveEnabled">
      <template v-slot:activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs">
          <ConfirmButton 
            :loading="loading" 
            color="success"
            :disabled="!isSaveEnabled"
            :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"
          >
            Salvar
          </ConfirmButton>
        </span>
      </template>
      <span>Selecione os itens correspondentes nas duas tabelas para habilitar o salvamento</span>
    </v-tooltip>
  </v-col>
</v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';

export default {
  name: "CriarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingEsterilizavel: false,
      isLoadingPacientes: false,
      pacientes: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataImplantacao: "",
      esterelizavel: [],
      selectedPacienteItems: [],
      selectedEsterelizaveis: [],
      filterPaciente: [], 
      isLoadingDados: false,
      ItemPacientelHeaders: [
        { text: "Esterilizável", value: "nome_esterilizavel" },
        { text: "Quantidade Solicitada", value: "quantidade" },
        { text: "Quantidade Marcada", value: "qtdMarcada" },
      ],
      esterelizavelHeaders: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "nome.nome" },
        { text: "Disponibilidade", value: "disponibilidade.nome" },
        { text: "Deposito", value: "deposito.nome" },
        { text: "Validade da Esterilização", value: "data_validade_esterilizacao" },
      ],
    });
  },
  components: { ConfirmButton },
  methods: {
   async getPacientes() {
      this.isLoadingPacientes = true;
      try {
        const response = await api("pacientes/esterilizaveis/");
        this.pacientes = response.data.filter(item => 
          // Tem data de requisição
          item.data_requisicao !== null && 
          // (!item.data_solicitacao_retirada || item.data_solicitacao_retirada === '') &&
          // Não tem data de implantação
          item.data_implantacao === null &&
          // Tem objeto nome_esterilizavel e ele tem nome
          item.nome_esterilizavel && 
          item.nome_esterilizavel.nome
        );
        
        // Primeiro agrupamento para juntar os materiais esterilizáveis
        const agrupamento = this.pacientes.reduce((acc, atual) => {
          // Ajustando a chave para considerar apenas a data sem a hora
          const dataReq = atual.data_requisicao.split('T')[0]; // Pega apenas a parte da data
          const chave = `${atual.paciente.id}-${dataReq}`;
          
          if (!acc[chave]) {
            // Formata a data de requisição
            const data = new Date(atual.data_requisicao);
            data.setDate(data.getDate() + 1);
            const dataFormatada = data.toLocaleDateString('pt-BR');
            
            // Inicia com um objeto que conta as ocorrências
            const contagem = {};
            contagem[atual.nome_esterilizavel.nome] = 1;

            acc[chave] = {
              id: chave, // Usando a chave composta como ID
              pacienteId: atual.paciente.id, // Mantém o ID original do paciente
              nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
              nome_esterilizavel: [{
                id: atual.nome_esterilizavel.id,
                nome: atual.nome_esterilizavel.nome,
                quantidade: 1
              }],
              data_requisicao: atual.data_requisicao,
              data_prevista_implantacao: atual.data_prevista_implantacao,
              _contagem: contagem
            };
          } else {
            // Atualiza a contagem do material
            const nomeEsterilizavel = atual.nome_esterilizavel.nome;
            acc[chave]._contagem[nomeEsterilizavel] = (acc[chave]._contagem[nomeEsterilizavel] || 0) + 1;
            
            // Verifica se o material já existe no array
            const materialExistente = acc[chave].nome_esterilizavel.find(item => item.nome === nomeEsterilizavel);
            
            if (materialExistente) {
              materialExistente.quantidade = acc[chave]._contagem[nomeEsterilizavel];
            } else {
              acc[chave].nome_esterilizavel.push({
                id: atual.nome_esterilizavel.id,
                nome: nomeEsterilizavel,
                quantidade: 1
              });
            }
          }
          return acc;
        }, {});

        // Converte para array final
        this.resumoPacientes = Object.values(agrupamento).map(item => ({
          id: item.id, // ID composto
          pacienteId: item.pacienteId, // ID original do paciente
          nome: item.nome,
          nome_esterilizavel: item.nome_esterilizavel,
          data_requisicao: item.data_requisicao,
          data_prevista_implantacao: item.data_prevista_implantacao
        }))
          .sort((a, b) => {
            // Converte as strings de data para objetos Date para comparação
            const dataA = new Date(a.data_requisicao);
            const dataB = new Date(b.data_requisicao);
            return dataA - dataB; // Ordem crescente (mais antigas primeiro)
          });

      } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
      } finally {
        this.isLoadingPacientes = false;
      }
    },
    updatePacienteData() {
      // Limpar seleções anteriores
      this.selectedPacienteItems = [];
      this.selectedEsterelizaveis = [];
      
      const paciente = this.resumoPacientes.find(
        (p) => p.id === this.pacienteSelecionado
      );
      
      if (paciente) {
        this.dataRequisicao = paciente.data_requisicao || "";
        this.dataImplantacao = paciente.data_prevista_implantacao || "";
        
        // Transformar os itens nome_esterilizavel em um formato adequado para a tabela
        this.filterPaciente = paciente.nome_esterilizavel.map(item => ({
          id: item.id,
          nome_esterilizavel: item.nome,
          quantidade: item.quantidade,
          qtdMarcada: 0
        }));
      } else {
        this.dataRequisicao = "";
        this.dataImplantacao = "";
        this.filterPaciente = [];
      }
    },
    async getEsterelizavel() {
      this.isLoadingEsterilizavel = true;
      try {
        const response = await api.get("equipamentos/esterelizavels/");
        const hoje = new Date();
        hoje.setHours(0, 0, 0, 0);
        this.esterelizavel = response.data
          .filter(item => {
            // Primeiro verifica disponibilidade
            if (item.disponibilidade?.id !== 6) return false;

            // Verifica se tem data de validade
            if (!item.data_validade_esterilizacao) return false;

            // Converte a data de validade para comparação
            const dataValidade = new Date(item.data_validade_esterilizacao);
            dataValidade.setHours(0, 0, 0, 0);

            // Retorna true apenas se a data de validade for maior que hoje
            return dataValidade > hoje;
          })
          .sort((a, b) => {
          // Primeiro comparamos os nomes dos itens
          const nomeA = a.nome?.nome?.toLowerCase() || '';
          const nomeB = b.nome?.nome?.toLowerCase() || '';
          
          if (nomeA !== nomeB) {
            return nomeA.localeCompare(nomeB);
          }

          // Se os nomes forem iguais, ordenamos por data de validade
          const dataA = a.data_validade_esterilizacao ? new Date(a.data_validade_esterilizacao) : new Date(9999, 11, 31);
          const dataB = b.data_validade_esterilizacao ? new Date(b.data_validade_esterilizacao) : new Date(9999, 11, 31);
          
          return dataA - dataB;
        });
      } catch (error) {
        this.$toast.error(`Erro ao carregar esterilizáveis: ${error}`);
      } finally {
        this.isLoadingEsterilizavel = false;
      }
    },
    compareItems() {
        if (!this.selectedPacienteItems.length || !this.selectedEsterelizaveis.length) {
            return;
        }
    },
    updateQuantidadeMarcada() {
      if (!Array.isArray(this.filterPaciente)) {
        return;
      }

      // Lista para armazenar todos os IDs que devem permanecer selecionados
      const idsParaManter = [];
      
      // Atualiza a tabela de pacientes e coleta os IDs que devem permanecer selecionados
      const pacienteAtualizado = this.filterPaciente.map(itemPaciente => {
        // Verifica se o item está selecionado
        const itemSelecionado = this.selectedPacienteItems.find(
          selected => selected.id === itemPaciente.id
        );

        if (!itemSelecionado) {
          return {
            ...itemPaciente,
            qtdMarcada: 0
          };
        }

        // Se está selecionado, encontra os esterilizáveis correspondentes
        const esterilizaveisCorrespondentes = this.esterelizavel.filter(
          esterilizavel => esterilizavel.nome?.id === itemPaciente.id
        );

        // Pega apenas a quantidade necessária de itens
        const itensSelecionados = esterilizaveisCorrespondentes
          .slice(0, itemPaciente.quantidade);

        // Guarda os IDs dos itens que devem permanecer selecionados
        itensSelecionados.forEach(item => {
          idsParaManter.push(item.id);
        });

        return {
          ...itemPaciente,
          qtdMarcada: itensSelecionados.length
        };
      });

      // Atualiza a seleção na tabela de esterilizáveis
      // Mantém apenas os itens que correspondem aos pacientes selecionados
      this.selectedEsterelizaveis = this.esterelizavel.filter(
        item => idsParaManter.includes(item.id)
      );

      // Atualiza filterPaciente
      this.filterPaciente = pacienteAtualizado;
    },
    async atualizarDados() {
      this.isLoadingDados = true;
      try {
        // Limpar seleções e dados
        this.selectedPacienteItems = [];
        this.selectedEsterelizaveis = [];
        this.pacienteSelecionado = null;
        this.dataRequisicao = "";
        this.dataImplantacao = "";
        this.filterPaciente = [];

        // Recarregar todos os dados
        await Promise.all([
          this.getEsterelizavel(),
          this.getPacientes()
        ]);

        this.$toast.success('Dados atualizados com sucesso!');
      } catch (error) {
        this.$toast.error('Erro ao atualizar os dados');
        console.error(error);
      } finally {
        this.isLoadingDados = false;
      }
    },
  },
  computed: {
    isSaveEnabled() {
      // Se não houver nenhuma seleção em nenhuma das tabelas, retorna false
      if (!this.selectedPacienteItems.length && !this.selectedEsterelizaveis.length) {
        return false;
      }

      // Para cada item selecionado na tabela de pacientes
      for (const itemPaciente of this.selectedPacienteItems) {
        const esterilizaveisCorrespondentes = this.selectedEsterelizaveis.filter(
          esterilizavel => esterilizavel.nome?.id === itemPaciente.id
        );

        // Se selecionou o item mas não selecionou nenhum esterilizável correspondente
        if (esterilizaveisCorrespondentes.length === 0) {
          return false;
        }

        // Se selecionou mais esterilizáveis do que a quantidade solicitada
        if (esterilizaveisCorrespondentes.length > itemPaciente.quantidade) {
          return false;
        }
      }

      // Verifica se não há excesso de esterilizáveis selecionados para itens não marcados
      for (const esterilizavel of this.selectedEsterelizaveis) {
        const itemPacienteCorrespondente = this.selectedPacienteItems.find(
          item => item.id === esterilizavel.nome?.id
        );

        // Se selecionou um esterilizável sem ter selecionado o item correspondente na tabela de pacientes
        if (!itemPacienteCorrespondente) {
          return false;
        }
      }

      // Se chegou até aqui, todas as validações passaram
      return true;
    }
  },
  watch: {
    selectedPacienteItems: {
    handler() {
      this.updateQuantidadeMarcada();
    },
    deep: true,
  }
  },
  mounted() {
    this.getEsterelizavel();
    this.getPacientes();
  },
};
</script>
<!-- feito primeira etapa -->