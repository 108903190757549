<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col >
        <v-text-field dense label="Esterilizável" v-model="currentItem.nome.nome" outlined  disabled/>
      </v-col>
      <v-col >
        <v-autocomplete :loading="isLoadingDeposito" dense label="Depósito" v-model="currentItem.deposito" outlined  :items="deposito" item-text="nome" item-value="id" />
      </v-col>
      <v-col >
        <v-autocomplete :loading="isLoadingDisponibilidade" dense label="Disponibilidade" v-model="currentItem.disponibilidade" outlined  :items="disponibilidade" item-text="nome" item-value="id"/>
      </v-col>
      <v-col cols="3" >
        <v-text-field type="date" outlined dense label="Data da Validade Esterilização" v-model="currentItem.data_validade_esterilizacao"> </v-text-field>
      </v-col>
    </v-row>
    
    <!-- Paciente -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="(usuario.length <= 0) && ![ 5, 9, 11].includes(currentDisponibilidadeId)"></v-divider>
    <h3 class="blod_color" v-if="(usuario.length <= 0) && ![ 5, 9, 11].includes(currentDisponibilidadeId)"> Dados do Paciente:</h3> <br>
    <v-row  v-if="(paciente.length > 0) && ![ 5, 9, 11].includes(currentDisponibilidadeId)">
      <v-col cols="2">
          <v-text-field :loading="isLoadingPaciente" dense  label="ID do Paciente em Uso"  :value="paciente.length > 0 ? paciente[0].paciente.id : ''"  outlined  readonly  />
      </v-col>
      <v-col cols="4">
          <v-text-field :loading="isLoadingPaciente" dense  label="Paciente em Uso"  :value="paciente.length > 0 ? paciente[0].paciente.nome : ''"  outlined  readonly  />
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingPaciente" dense  label="Data da Requisição"  :value="paciente.length > 0 ? paciente[0].data_requisicao : ''" type="date" outlined readonly  />  
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingPaciente" dense  label="Data da Implantação"  :value="paciente.length > 0 ? paciente[0].data_implantacao : ''"   type="date" outlined readonly  />  
      </v-col>
      <v-col cols="2" v-if="(paciente.length > 0) && ![ 5, 9, 11].includes(currentDisponibilidadeId)">
        <v-btn-toggle  v-model="paciente[0].ativo" mandatory  class="white--text">
          <v-btn  :value="false" :color="paciente[0].ativo === false ? 'error' : ''" class="white--text"  > Inativo </v-btn>
          <v-btn  :value="true" :color="paciente[0].ativo === true ? 'green' : ''" class="white--text" > Ativo </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row v-if="(usuario.length <= 0) && ![ 5, 9, 11].includes(currentDisponibilidadeId)">
      <v-col cols="4">
          <v-autocomplete  :loading="isLoadingPacientDisponivel" dense  label="Selecione o Paciente"  :items="pacienteDisponivel" item-text="nome_formatado" item-value="id" outlined  />
      </v-col>
      <v-col cols="3">
        <v-text-field :loading="isLoadingPacientDisponivel" dense  label="Data da Implantação"  type="date"  outlined clearable />  
      </v-col>
    </v-row>








    <!-- Usuário -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="(paciente.length <= 0 && ![ 5, 9, 11].includes(currentDisponibilidadeId))"></v-divider>
    <h3 class="blod_color" v-if="(paciente.length <= 0 && ![ 5, 9, 11].includes(currentDisponibilidadeId))"> Dados do Usuário "Profissional":</h3> <br>
    <v-row v-if="(usuario.length > 0) && ![ 5, 9, 11].includes(currentDisponibilidadeId)">
      <v-col cols="1">
          <v-text-field :loading="isLoadingUsuario" dense  label="ID do Usuário em Uso"  :value="usuario.length > 0 ? usuario[0].usuario.id : ''"  outlined  readonly  />
      </v-col>
      <v-col cols="3">
          <v-text-field :loading="isLoadingUsuario" dense  label="Usuário em Uso"  :value="usuario.length > 0 ? usuario[0].usuario.apelido : ''"  outlined  readonly />
      </v-col>
      <v-col cols="2">
          <v-text-field :loading="isLoadingUsuario" dense  label="Especialidade"  :value="usuario.length > 0 ? usuario[0].usuario.especialidade.nome : ''"  outlined  readonly  />
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingUsuario" dense  label="Data da Requisição"  :value="usuario.length > 0 ? usuario[0].data_requisicao : ''" type="date" outlined readonly  />  
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingUsuario" dense  label="Data da Entrega"  :value="usuario.length > 0 ? usuario[0].data_entrega : ''"   type="date" outlined readonly  />  
      </v-col>
      <v-col cols="2" v-if="usuario.length > 0">
        <v-btn-toggle  v-model="usuario[0].ativo" mandatory  class="white--text">
          <v-btn  :value="false" :color="usuario[0].ativo === false ? 'error' : ''" class="white--text"  > Inativo </v-btn>
          <v-btn  :value="true" :color="usuario[0].ativo === true ? 'green' : ''" class="white--text" > Ativo </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row v-if="(paciente.length <= 0 && ![ 5, 9, 11].includes(currentDisponibilidadeId))">
      <v-col cols="4">
          <v-autocomplete  :loading="isLoadingUsuarioDisponivel" dense  label="Selecione o Usuário"  :items="usuarioDisponivel" item-text="nome_formatado" item-value="id" outlined  @change="handleUsuarioChange"/>
      </v-col>
      <v-col cols="2">
          <v-text-field :loading="isLoadingUsuario" dense  label="Especialidade" :value="usuarioSelecionado?.especialidade?.nome" outlined  disabled />
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingUsuarioDisponivel"  dense  label="Data da Entrega"  type="date"  outlined clearable />  
      </v-col>
    </v-row>



    <!-- Empresa de Esterelização -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;" v-if="(paciente.length <= 0 ) && (usuario.length <=0) && ![1, 3, 4, 5, 7, 9, 11].includes(currentDisponibilidadeId)" ></v-divider>
    <h3 class="blod_color" v-if="(paciente.length <= 0 ) && (usuario.length <=0) && ![1, 3, 4, 5, 7, 9, 11].includes(currentDisponibilidadeId)">Enviar para Esterilização:</h3> <br>
    <v-row v-if="(paciente.length <= 0 ) && (usuario.length <=0) && ![1, 3, 4, 5, 7, 9, 11].includes(currentDisponibilidadeId)">
      <v-col cols="4">
          <v-autocomplete  :loading="isLoadingFornecedor" dense  label="Empresa Esterelizadora"  :items="fornecedorEsterelizacao" item-text="nome_fantasia" item-value="id" outlined  v-model="currentItem.esterilizacao_fornecedor"/>
      </v-col>
      <v-col cols="2">
        <v-text-field :loading="isLoadingFornecedor" dense  label="Data do Envio"  type="date" outlined clearable v-model="currentItem.esterilizacao_data_envio"/>  
      </v-col>
    </v-row>

    <!-- Dados Gerais -->
    <v-divider class="my-4 border-opacity-75" style="border-width: 3px !important;"></v-divider>
    <h3 class="blod_color"> Fornecedor do Esterilizável "Compra":</h3> <br>
    <v-row dense>
      <v-col cols="3">
        <v-autocomplete :loading="isLoadingFornecedor"  dense  label="Fornecedor"  v-model="currentItem.fornecedor"  :value="fornecedorInicial" outlined   :items="fornecedorAquisicao"  item-text="nome_fantasia"  item-value="id" disabled></v-autocomplete>
      </v-col>
      <v-col cols="2">
        <vuetify-money dense label="Valor Compra R$" v-model="currentItem.valor_final" outlined  />
      </v-col>
      <v-col cols="2">
        <v-text-field  outlined dense label="Data da Baixa" v-model="currentItem.data_baixa" type="date" clearable ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field  outlined dense label="Observação" v-model="currentItem.obs"></v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';

export default {
  name: "EditarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      isLoadingDisponibilidade: false, // Status de carregamento
      isLoadingDeposito: false, // Status de carregamento
      isLoadingFornecedor: false,
      isLoadingPaciente: false,
      isLoadingPacientDisponivel: false,
      isLoadingUsuario: false,
      isLoadingUsuarioDisponivel: false, 
      disponibilidade: [],
      deposito: [],
      fornecedor:[],
      paciente: [],
      usuario: [],
      usuarioDisponivel: [],
      pacienteDisponivel: [],
      fornecedorAquisicao: [],
      fornecedorEsterelizacao: [],
      selectedPaciente: null,
      selectedUsuario: null,
      usuarioSelecionado: null
    });
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;
       if (this.currentDisponibilidadeId === 7 & (!this.currentItem.esterilizacao_data_envio || !this.currentItem.esterilizacao_fornecedor)) {
          this.$toast.error("Preencha a Empresa de Esterilizaçao e a Data de Envio.");
          return;
      }

      const fields = {
          ... this.currentItem,
      };

      // console.log('Enviando para o backend:', fields);
      this.onSubmit(fields); // Certifique-se de que `onSubmit` está configurado para fazer um PUT corretamente
    },
    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data.filter(item => item.ativo)
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
    async getDeposito() {
      this.isLoadingDeposito = true;
      try {
        const { data } = await api.get(`/uteis/deposito/`);
        this.deposito = data.filter(item => item.ativo)
        // console.log(this.deposito)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDeposito = false;
      }
    },
    async getFornecedor() {
      this.isLoadingFornecedor = true
      try {
        const { data } = await api('fornecedores/fornecedores/')
        this.fornecedorAquisicao = data.filter(item => item.ativo).sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia))
        this.fornecedorEsterelizacao = data.filter(item => 
            item.tipo && item.tipo.some(tipo => tipo.id === 8)
        ).sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
        // console.log('Fornecedor --< ',this.fornecedor)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (L-228). ${error}`);
      } finally {
        this.isLoadingFornecedor = false
      }
    },
    //  handlePacienteChange(pacienteId) {
    //   if (!pacienteId) {
    //     // Se limpar a seleção
    //     this.currentItem.paciente = null;
    //     this.currentItem.data_requisicao = null;
    //     return;
    //   }

    //   const pacienteSelecionado = this.pacienteDisponivel.find(p => p.id === pacienteId);
    //   if (pacienteSelecionado) {
    //     this.currentItem.paciente = pacienteSelecionado.id;
    //     this.currentItem.paciente_nome = pacienteSelecionado.nome;
    //     // Define a data de requisição como a data atual se não existir
    //     if (!this.currentItem.data_requisicao) {
    //       this.currentItem.data_requisicao = new Date().toISOString().split('T')[0];
    //     }
    //   }
    // },
    async getPacienteDisponivel() {
      this.isLoadingPacientDisponivel = true;
      try {
        const response = await api('pacientes/pacientes_simples/');
        this.pacienteDisponivel = response.data
        // console.log('Paciente Disponivel', this.pacienteDisponivel )
      } catch (error) {
        this.$toast.error(`Erro ao buscar pacientes ativos: ${error}`);
      } finally {
        this.isLoadingPacientDisponivel = false;
      }
    },
    async getPaciente() {
      this.isLoadingPaciente = true;
      try {
        const { data } = await api('pacientes/esterilizaveis/');
        this.paciente = data
          .filter(item => item.esterilizavel?.id === this.currentItem.id && item.ativo)
          .map(item => ({
            ...item,
            ativo: Boolean(item.ativo) // Converte explicitamente para boolean
          }));
        // console.log('Paciente --< ', this.paciente);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro getPaciente. ${error}`);
      } finally {
        this.isLoadingPaciente = false;
      }
    },
    async getUsuarioEsterilizavel() {
      this.isLoadingUsuario = true
      try {
        const { data } = await api('equipamentos/esterelizavel_usuarios/')
        this.usuario = data
          .filter(item => item.ativo && item.esterilizavel?.id === this.currentItem.id)
          .map(item => ({
            ...item,
            ativo: Boolean(item.ativo) // Converte explicitamente para boolean
          }));
        // console.log('Usuario --< ',this.usuario)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários. ${error}`);
      } finally {
        this.isLoadingUsuario = false
      }
    },
    async getUsuarioDisponivel() {
      this.isLoadingUsuarioDisponivel = true
      try {
        const { data } = await api('contas/usuario_simples/')
        this.usuarioDisponivel = data
        // console.log('Usuario Disponivel --< ',this.usuarioDisponivel)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários disponíveis. ${error}`);
      } finally {
        this.isLoadingUsuarioDisponivel = false
      }
    },
    handleUsuarioChange(id) {
     this.usuarioSelecionado = this.usuarioDisponivel.find(u => u.id === id)
   },
  },
  computed: {
    currentDisponibilidadeId() {
      // Garante que verifica o ID mesmo que seja um objeto
      return typeof this.currentItem.disponibilidade === "object"
        ? this.currentItem.disponibilidade?.id
        : this.currentItem.disponibilidade;
    },
    fornecedorInicial() {
      // Retorna o ID do fornecedor da aquisição se existir
      return this.currentItem?.aquisicao?.fornecedor?.id || null;
    },
  },
  watch: {
    'currentItem.disponibilidade': {
      handler(newVal) {
        const disponibilidadeId = typeof newVal === 'object' ? newVal.id : newVal;
        if (disponibilidadeId === 9) {
          this.previousAtivoState = this.currentItem.ativo;
          this.currentItem.ativo = false;
        } else if (this.previousAtivoState !== undefined) {
          this.currentItem.ativo = this.previousAtivoState;
        }
      },
      deep: true
    },
     fornecedor(newFornecedorList) {
      if (newFornecedorList.length === 1 && this.currentDisponibilidadeId === 7) {
        this.currentItem.esterilizacao_fornecedor = newFornecedorList[0].id;
      }
    },
  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
    // this.currentItem.esterilizacao_fornecedor = null 
    this.currentItem.fornecedor = this.fornecedorInicial;
    this.getPaciente()
    this.getPacienteDisponivel()
    this.getUsuarioEsterilizavel()
    this.getUsuarioDisponivel()
    this.getDisponibilidade()
    this.getDeposito()
    this.getFornecedor()
    // // Garante que o campo fornecedor aparece se a disponibilidade inicial for 7
    // if (this.currentDisponibilidadeId === 7 && this.fornecedor.length === 1) {
    //   this.currentItem.esterilizacao_fornecedor = this.fornecedor[0].id;
    // }
   }

};
</script>
