<template>
    <v-row justify="center">
        <v-col cols="12">
            <v-data-table :loading="isTableLoading" :items="esterelizavel" :headers="headersWithValidadeInfo" :search="search"
            class="fixed-header-table" fixed-header height="600px" :items-per-page="-1" >
                <template v-slot:top>
                    <v-row class="mt-2">
                        <v-col cols="5">
                            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable/>
                        </v-col>
                        <v-col class="text-end">
                            <v-btn class="mr-4 elevation-0" text @click="atualizar"> <v-icon left>mdi-reload</v-icon> Atualizar </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2 mb-2">
                        <!-- Wrapper dos botões alinhados à esquerda -->
                        <v-col cols="auto" class="d-flex gap-4"> <!-- gap-4 adiciona espaçamento entre os botões -->
                            <!-- Botão Novo Esterilizavel-->
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn  color="success"  class="elevation-0 mr-2"  @click="openModal"  v-bind="attrs"  v-on="on" > <v-icon left>mdi-plus</v-icon><div class="d-flex flex-column"> <span>Novo</span> <span>Esterilizável</span> </div> </v-btn>
                                </template>
                                <span>Clique para cadastrar um novo esterilizável</span>
                            </v-tooltip>
                            <!-- Botão Enviar Esterilizáveis -->
                            <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  color="accent"  class="elevation-0 mr-2"  @click="dialog.enviar = true"  v-bind="attrs"  v-on="on" > <v-icon left>mdi-plus</v-icon>  <div class="d-flex flex-column"> <span>Enviar p/</span> <span>Esterilização</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para enviar um ou mais Esterilizáveis para Esterilização</span>
                                </v-tooltip>
                                <div v-if="quantidadeEsterilizavelEnviar >=1" class="badge-red">
                                    {{ quantidadeEsterilizavelEnviar }}
                                </div>
                            </div>
                            <!-- Botão Receber Esterilizáveis -->
                            <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  color="success"  class="elevation-0 mr-2"  @click="dialog.create = true"  v-bind="attrs"  v-on="on" > <v-icon left>mdi-plus</v-icon>  <div class="d-flex flex-column"> <span>Receber da</span> <span>Esterilização</span> </div>  </v-btn>
                                    </template>
                                    <span>Clique para receber uma Nota de Esterilização</span>
                                </v-tooltip>
                                <div v-if="quantidadeEsterilizavelreceber >=1" class="badge-red">
                                    {{ quantidadeEsterilizavelreceber }}
                                </div>
                            </div>
                            <!-- Botão Trocar Esterilizáveis -->
                            <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="accent" class="elevation-0 mr-2" @click="dialog.createTroca = true" v-bind="attrs" v-on="on" > <v-icon left>mdi-plus</v-icon> <div class="d-flex flex-column"> <span>Trocar</span> <span>Esterilizável</span> </div> </v-btn>
                                    </template>
                                    <span>Clique para substituir Esterilizavel em domicílio</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveisTroca >=1" class="badge-red">
                                    {{ quantidadePacientesElegiveisTroca }}
                                </div>
                            </div>
                            <!-- Botão Retirada de Esterilizáveis -->
                             <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn  color="success"  class="elevation-0 mr-2"  @click="dialog.retirada = true"  v-bind="attrs"  v-on="on" > <v-icon left>mdi-plus</v-icon>  <div class="d-flex flex-column"> <span>Retirar</span> <span>Esterilizável</span> </div>  </v-btn>
                                    </template>
                                    <span>Clique para substituir Esterilizavel em domicílio</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveisRetirada >=1" class="badge-red">
                                    {{ quantidadePacientesElegiveisRetirada }}
                                </div>
                            </div>

                            <!-- Botão Requisições com badge -->
                            <div style="position: relative;">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn color="accent" class="elevation-0 mr-2" @click="dialog.createRequisicao = true" v-bind="attrs" v-on="on" > <v-icon left>mdi-plus</v-icon> Requisições </v-btn>
                                    </template>
                                    <span>Clique para Atender Requisição</span>
                                </v-tooltip>
                                <div v-if="quantidadePacientesElegiveis >= 1" class="badge-red">
                                    {{ quantidadePacientesElegiveis }}
                                </div>
                            </div>
                        </v-col>
                        <v-col class="text-end mr-5">
                            <div v-if="validade > 0" class="d-flex align-center justify-end">
                                <v-icon color="error" left>mdi-alert</v-icon>
                                <span class="error--text font-weight-medium">
                                    {{ validade }} {{ validade === 1 ? 'Item Vencido' : 'Itens Vencidos' }}
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.edit`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                                <v-icon color="green">mdi-pencil</v-icon>
                            </v-btn>
                        </template>
                        <span>Clique para editar</span>
                    </v-tooltip>
                </template>
                <template v-slot:[`item.esterilizacao_fornecedor_nome`]="{ item }">
                    <div v-if="isTableLoading" class="d-flex justify-center align-center">
                        <v-progress-circular indeterminate color="primary" size="24"></v-progress-circular>
                    </div>
                    <div v-else>
                        <span>{{ item.esterilizacao_fornecedor_nome }}</span>
                    </div>
                </template>                
                <template v-slot:[`item.esterilizacao_data_envio`]="{ item }">
                    {{ item.esterilizacao_data_envio | formatDatabr }}
                </template>
                <template v-slot:[`item.paciente_nome`]="{ item }">
                    {{ item.paciente_nome ? item.paciente_nome : item.usuariosEsterilizavel_nome  }}
                </template>
                <template v-slot:[`item.ativo`]="{ item }">
                    <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
                        <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
                    </v-chip>
                </template>
                <template v-slot:[`item.data_requisicao`]="{ item }">
                    {{ item.data_requisicaoPaciente ? $options.filters.formatDatabr(item.data_requisicaoPaciente) : $options.filters.formatDatabr(item.data_requisicaoUsuario) }}
                </template>
                <template v-slot:[`item.data_implantacao`]="{ item }">
                    {{ item.data_implantacaoPaciente ? $options.filters.formatDatabr(item.data_implantacaoPaciente) : $options.filters.formatDatabr(item.data_entregaUsuario) }}
                </template>
                <template v-slot:[`item.data_validade_esterilizacao`]="{ item }">
                    <span v-if="item.disponibilidade.id !== 7 ">{{ item.data_validade_esterilizacao | formatDatabr }}</span>
                    <span v-else></span>
                </template>

            </v-data-table>
<!-- Editar -->
            <v-dialog v-model="dialog.update" v-if="dialog.update" max-width="90%">
                <v-card>
                    <v-card-title class="blod_color">
                        Editar Esterilizável - ID {{selectedRow.id}} - {{selectedRow.nome.nome}}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog.update = false"> <v-icon>  mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <EditarEsterilizavel :currentItem="selectedRow" :loading="isLoading('put:esterilizavel')" :onSubmit="updateEsterilizavel" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Novo Esterilizavel-->
            <v-dialog v-model="dialog.create2" max-width="90%">
                <v-card>
                    <v-card-title class="sticky-title title-border">
                        Cadastro de Novo Esterilizável:
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeModal"> <v-icon>  mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <EntradaEsterilizaveis :currentItem="newItem" :loading="isLoading('post:esterilizavel')" :onSubmit="createEsterilizavel" />
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Enviar para Esterilização -->
            <v-dialog v-model="dialog.enviar" max-width="90%">
                <v-card>
                    <v-card-title class="sticky-title title-border">
                        Enviar Esterilizáveis para Esterilização
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeModalEnviar"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <EnviarEsterilizaveis :currentItem="newItem" :loading="isLoading('put:esterilizavelenviar')" :onSubmit="updateEsterilizavelEnviar"/>
                     </v-card-text>
                </v-card>
            </v-dialog>

<!-- Receber Esterelizáveis -->
            <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="90%">
                <v-card>
                    <v-card-title class="blod_color">
                    Nova Nota de Esterilização:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog.create = false">
                        <v-icon>
                        mdi-close
                        </v-icon>
                    </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <EsterilizacaoCriar :currentItem="newItem" :loading="isLoading('post:esterilizacao')" :onSubmit="upadteEsterelizavelReceber" />
                    </v-card-text>
                </v-card>
            </v-dialog>
<!-- Trocar -->
            <v-dialog v-model="dialog.createTroca" v-if="dialog.createTroca" max-width="90%">
                <v-card>
                    <v-card-title class="blod_color">
                        Solicitação de Troca de Esterilizáveis do Paciente
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog.createTroca = false"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <Trocar :currentItem="newItem" :loading="isLoading('put:esterilizaveltrocar')" :onSubmit="updateEsterilizavelTrocar"/>
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Retirada -->
            <v-dialog v-model="dialog.retirada" v-if="dialog.retirada" max-width="90%">
                <v-card>
                    <v-card-title class="blod_color">
                        Solicitação de Retirada de Esterilizáveis do Paciente
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog.retirada = false"> <v-icon> mdi-close  </v-icon> </v-btn>
                    </v-card-title>
                     <v-card-text>
                         <Retirada :currentItem="newItem" :loading="isLoading('put:esterilizavelretirada')" :onSubmit="updateEsterilizavelRetirada"/>
                     </v-card-text>
                </v-card>
            </v-dialog>
<!-- Requisição -->
            <v-dialog v-model="dialog.createRequisicao" v-if="dialog.createRequisicao" max-width="80%" >
                <v-card>
                    <v-card-title class="blod_color">
                    Requisição:
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog.createRequisicao = false">  <v-icon> mdi-close </v-icon> </v-btn>
                    </v-card-title>
                    <v-card-text>
                    <Requisicao :currentItem="newItem" :loading="isLoading('post:esterilizacao')" :onSubmit="createEsterilizacao" />
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
import api from '../../../http';
import UtilsFunc from "../../../service/utilsFunc";
import EntradaEsterilizaveis from './EntradaEsterilizaveis.vue';
import EditarEsterilizavel from './EditarEsterilizavel.vue';
import EsterilizacaoCriar from './EsterilizaçãoReceber.vue';
import EnviarEsterilizaveis from './EnviarEsterilizaveis.vue';
import Requisicao from './Requisicao.vue'
import Trocar from './TrocaEsterilizavel.vue';
import Retirada from './RetiradaEsterilizaveis.vue';
import { mapMutations } from 'vuex'

const { withCRUDUtils } = UtilsFunc

export default {
    name: 'ControleEsterilivaveis',
    components: {
        EntradaEsterilizaveis,
        EditarEsterilizavel,
        EsterilizacaoCriar,
        Requisicao,
        EnviarEsterilizaveis,
        Trocar,
        Retirada,
    },
    data: () => withCRUDUtils ({
        isTableLoading: true,
        dialog: {
            create2: false,
            enviar: false,
            createTroca: false,
            createReceber: false,
            createRequisicao: false,
            retirada: false,
        },
        modal: false,
        esterelizavel: [],  
        fornecedor: [], 
        paciente: [],
        pacientes: [],
        usuariosEsterilizavel: [],
        loading: false,
        search: '',
        singleExpand: false,
        showFilters: false,
        tabIndex: 0,
        validade: 0,
        quantidadePacientesElegiveis: 0,
        quantidadePacientesElegiveisTroca: 0,
        quantidadePacientesElegiveisRetirada: 0,
        quantidadeEsterilizavelreceber: 0,
        quantidadeEsterilizavelEnviar: 0,
        newItem: {
            ativo: true
        },
        newItemAquisicao: {
            ativo: true
        },
        filters: {
            fornecedor: null,
            razaoSocial: null,
        },
        headers: [
            { text: "Editar", value: "edit" },
            { text: "Id", align: "center", sortable: true, value: "id" },
            { text: "Descrição", align: "center", sortable: true, value: "nome.nome" },
            { text: "Deposito", align: "center", sortable: true, value: "deposito.nome" },
            { text: "Disponibilidade", align: "center", sortable: true, value: "disponibilidade.nome" },
            // { text: "Usuário", align: "start", sortable: true, value: "usuariosEsterilizavel_nome", width: 200 },
            { text: "Paciente ou Usuário", align: "start", sortable: true, value: "paciente_nome", width: 200 },
            { text: "Dia Dispensação", align: "center", sortable: true, value: "paciente_dia_dispensacao_esterilizavel", width: 30 },
            { text: "Data Requisição", align: "start", sortable: true, value: "data_requisicao" },
            { text: "Data da Entrega", align: "start", sortable: true, value: "data_implantacao" },
            { text: "Esterilizadora", align: "start", sortable: true, value: "esterilizacao_fornecedor_nome" },
            { text: "Data do Envio Esterilização", align: "start", sortable: true, value: "esterilizacao_data_envio" },
            { text: "Data da Validade Esterilização", align: "start", sortable: true, width: 140, value: "data_validade_esterilizacao" },
            { text: "Status", value: "ativo" },
        ],
    }), 
    methods: {
        ...mapMutations(['setModal']),
        openModal() {
            this.dialog.create2 = true
            this.modal = true
            this.setModal(this.modal)
        },
        openModalReceber() {
            this.dialog.createReceber = true
            this.modal = true
            this.setModal(this.modal)
        },
        closeModal() {
            this.dialog.create2 = false
            this.modal = false
            this.setModal(this.modal)
        },
        closeModalReceber() {
            this.dialog.createReceber = false;
            this.modal = false;
            this.setModal(this.modal);
        },
        closeModalEnviar() {
            this.dialog.enviar = false;
            this.modal = false;
            this.setModal(this.modal);
        },
        ...mapMutations(['setModal']),
        carregarDadosIniciais() {
            this.getPaciente()
            this.getUsuarioEsterilizavel()
            this.getFornecedor()
            this.getEsterelizavel()
            this.isTableLoading = true; // Ativa o loading geral da tabela

            Promise.all([this.getEsterelizavel(), this.getFornecedor(), this.getPaciente()])
                .then(() => {
                    this.enriquecerEsterelizaveisComFornecedor();
                    this.enriquecerEsterelizaveisComPaciente();
                    this. enriquecerEsterelizaveisComUsuario();
                })
                .catch((error) => {
                    console.error("Erro ao carregar dados iniciais:", error);
                    this.$toast.error("Erro ao carregar dados iniciais.");
                })
                .finally(() => {
                    this.isTableLoading = false; // Desativa o loading após tudo ser carregado
                });
        },
        atualizar() {
            this.carregarDadosIniciais();
            this.search = ''; // Limpa o campo de busca
        },
        async getFornecedor() {
          try {
                const { data } = await api('fornecedores/fornecedores/')
                this.fornecedor = data
                // console.log('Fornecedor --< ',this.fornecedor)
            } catch (error) {
                this.$toast.error(`Desculpe. Apresentou algum erro getFornecedor. ${error}`);
            } 
        },
        async getPaciente() {
            try {
                const { data } = await api('pacientes/esterilizaveis/');
                this.pacientes = data.filter(item => item.ativo)
                const pacientesAgrupados = {};

                // Agrupa primeiro por paciente.id e data_solicitacao_retirada
                const retiradas = data.reduce((acc, paciente) => {
                    if (paciente.data_solicitacao_retirada && paciente.esterilizavel && paciente.ativo) {
                        const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_retirada}`;
                        if (!acc[chave]) {
                            acc[chave] = true;
                        }
                    }
                    return acc;
                }, {});

                // Conta o número de chaves únicas (combinações únicas de paciente.id e data_solicitacao_retirada)
                this.quantidadePacientesElegiveisRetirada = Object.keys(retiradas).length;

                // Mesmo processo para solicitações de troca
                const trocas = data.reduce((acc, paciente) => {
                    if (paciente.data_solicitacao_troca && paciente.esterilizavel && paciente.ativo) {
                        const chave = `${paciente.paciente.id}-${paciente.data_solicitacao_troca}`;
                        if (!acc[chave]) {
                            acc[chave] = true;
                        }
                    }
                    return acc;
                }, {});

                this.quantidadePacientesElegiveisTroca = Object.keys(trocas).length;

                data.forEach(paciente => {
                const chave = `${paciente.paciente.id}-${paciente.data_requisicao}`;
                if (!pacientesAgrupados[chave]) {
                    pacientesAgrupados[chave] = {
                    paciente: paciente.paciente,
                    data_requisicao: paciente.data_requisicao,
                    data_solicitacao_troca: paciente.data_solicitacao_troca,
                    data_solicitacao_retirada : paciente.data_solicitacao_retirada,
                    data_prevista_implantacao: paciente.data_prevista_implantacao,
                    data_implantacao: paciente.data_implantacao,
                    ativo: paciente.ativo,
                    nome_esterilizavel: [
                        {
                        id: paciente.nome_esterilizavel.id,
                        nome: paciente.nome_esterilizavel.nome,
                        quantidade: paciente.quantidade
                        }
                    ]
                    };
                } else {
                    pacientesAgrupados[chave].nome_esterilizavel.push({
                    id: paciente.nome_esterilizavel.id,
                    nome: paciente.nome_esterilizavel.nome,
                    quantidade: paciente.quantidade
                    });
                }
                });

                this.paciente = Object.values(pacientesAgrupados);

                const pacientesElegiveis = this.paciente.filter(paciente =>
                paciente.data_requisicao &&
                paciente.ativo === true &&
                (!paciente.data_implantacao || paciente.data_implantacao === '') 
                );

                this.quantidadePacientesElegiveis = pacientesElegiveis.length;

            } catch (error) {
                console.log(error)
                this.$toast.error(`Desculpe. Apresentou algum erro getPaciente. ${error}`);
            }
        },
        async getEsterelizavel() {

            try {
                const response = await api.get('equipamentos/esterelizavels/')
                const ordemDisponibilidade = [4, 3, 7, 6, 9, 1, 2, 5]; // Ordem desejada

                this.esterelizavel = response.data.sort((a, b) => {
                    if (a.ativo !== b.ativo) {
                        return b.ativo - a.ativo; // Ativos primeiro
                    }
                    // Ordenação pela disponibilidade
                    const indexA = ordemDisponibilidade.indexOf(a.disponibilidade?.id || -1);
                    const indexB = ordemDisponibilidade.indexOf(b.disponibilidade?.id || -1);

                    if (indexA !== indexB) {
                        return indexA - indexB; // Ordem por disponibilidade
                    }

                    // Se as disponibilidades forem iguais, ordena pelo nome do esterilizável
                    const nomeA = a.nome?.nome?.toLowerCase() || '';
                    const nomeB = b.nome?.nome?.toLowerCase() || '';
                    return nomeA.localeCompare(nomeB); // Ordem alfabética
                });
                // Pegar a data de hoje
                const hoje = new Date();
                hoje.setHours(0, 0, 0, 0); // Zerar horas para comparar apenas as datas

                // Filtrar itens que precisam de atenção na validade
                this.validade = this.esterelizavel.filter(item => {
                    // Verificar se não está em esterilização (id diferente de 7)
                    const naoEstaEmEsterilizacao = item.disponibilidade?.id !== 7;
                    
                    // Verificar data de validade
                    if (item.data_validade_esterilizacao) {
                        const dataValidade = new Date(item.data_validade_esterilizacao);
                        dataValidade.setHours(0, 0, 0, 0);
                        const dataExpirada = dataValidade <= hoje;
                        
                        return naoEstaEmEsterilizacao && dataExpirada;
                    }
                    
                    return false;
                }).length;

                this.quantidadeEsterilizavelreceber = this.esterelizavel.filter(item => item.ativo && item.disponibilidade.id === 7).length;
                this.quantidadeEsterilizavelEnviar = this.esterelizavel.filter(item => item.ativo && item.disponibilidade.id === 10).length;

                // console.log('Quantidade de itens com validade expirada:', this.validade);
                // console.log('Esterelizavel ordenado -> ', this.esterelizavel);
            } catch(error) {
                console.error(error)
            } 
        },
        async getUsuarioEsterilizavel() {
            try {
                const { data } = await api('equipamentos/esterelizavel_usuarios/')
                this.usuariosEsterilizavel = data.filter(item => item.ativo)
                // console.log('Usuario --< ',this.usuariosEsterilizavel)
            } catch (error) {
                this.$toast.error(`Desculpe. Apresentou algum erro ao buscar usuários. ${error}`);
            } 
            },
        enriquecerEsterelizaveisComFornecedor() {

            if (!this.esterelizavel || !this.fornecedor) return;

            // Associa o nome do fornecedor ao esterelizável
            this.esterelizavel = this.esterelizavel.map((item) => {
                if (item.esterilizacao_fornecedor) {
                    const fornecedor = this.fornecedor.find(
                        (f) => f.id === item.esterilizacao_fornecedor
                    );
                    item.esterilizacao_fornecedor_nome = fornecedor ? fornecedor.nome_fantasia : "N/A";
                } 
                return item;
            });

        },
        enriquecerEsterelizaveisComPaciente() {
            if (!this.esterelizavel || !this.pacientes) return;

            // Associa o nome do paciente ao esterilizável
            this.esterelizavel = this.esterelizavel.map((item) => {
                const pacienteAssociado = this.pacientes.find(
                    (pac) => pac.esterilizavel?.id === item.id
                );

                item.paciente_nome = pacienteAssociado ? pacienteAssociado.paciente.nome : "";
                item.paciente_dia_dispensacao_esterilizavel = pacienteAssociado ? pacienteAssociado.paciente.dia_dispensacao_esterilizavel : "";
                item.data_requisicaoPaciente = pacienteAssociado ? pacienteAssociado.data_requisicao : null;
                item.data_implantacaoPaciente = pacienteAssociado ? pacienteAssociado.data_implantacao : null;
                return item;
            });
        },
        enriquecerEsterelizaveisComUsuario() {
            if (!this.esterelizavel || !this.usuariosEsterilizavel) return;

            // Associa o nome do paciente ao esterilizável
            this.esterelizavel = this.esterelizavel.map((item) => {
                const usuarioAssociado = this.usuariosEsterilizavel.find(
                    (pac) => pac.esterilizavel?.id === item.id
                );

                item.usuariosEsterilizavel_nome = usuarioAssociado ? `${usuarioAssociado.usuario.apelido} - ${usuarioAssociado.usuario.especialidade.nome}` : "";
                item.data_requisicaoUsuario = usuarioAssociado ? usuarioAssociado.data_requisicao : null;
                item.data_entregaUsuario = usuarioAssociado ? usuarioAssociado.data_entrega : null;
                return item;
            });
        },
        async createEsterilizavel(fields) {
            const LOADING_NAME = "post:esterilizavel"
            this.setLoading(LOADING_NAME)
            // console.log('Item Enviado --> ', fields)
            try {
                await api.post('equipamentos/esterelizavel_criar/', fields)
                this.getEsterelizavel()
                this.$toast.success('Esterilizável Criado com Sucesso')
                this.dialog.create2 = false
                this.atualizar()
            } catch (error) {
                console.error(error)
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
        async updateEsterilizavel(fields) {
            const LOADING_NAME = "put:esterilizavel"
            this.setLoading(LOADING_NAME)
            try {
                await api.put(`/equipamentos/esterelizavel/${fields.id}/`, fields)
                this.getEsterelizavel()
                 this.dialog.update = false
                this.$toast.success('Esterilizável Editado com Sucesso')
            } catch (error) {
                console.error(error)
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
       async updateEsterilizavelEnviar(fields) {
            const LOADING_NAME = "put:esterilizavelenviar";
            this.setLoading(LOADING_NAME);
            // console.log('Item Enviado --> ', fields)
            try {
                await api.put(`equipamentos/esterelizavel_editar/${fields.id}/`, fields);
                this.$toast.success('Esterilizável Enviado com Sucesso')
                this.dialog.enviar = false
                this.atualizar()
            } catch (error) {
                console.error(`Erro ao atualizar o esterilizável ${fields.id}:`, error);
                this.$toast.error(  `Erro ao atualizar o item ${fields.id}: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar(); // Atualiza a tabela
            }
        },
       async upadteEsterelizavelReceber(fields) {
            const LOADING_NAME = "post:esterilizacao"; 
            this.setLoading(LOADING_NAME);
            // console.log('Criando a Nota --> ', fields)
            try {
                await api.post(`/equipamentos/esterilizacao_criar/`, fields );
                this.dialog.create = false
                this.$toast.success('Esterilização atualizada com sucesso!')
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar()
            }
        },
       async updateEsterilizavelTrocar(fields) {
            const LOADING_NAME = "put:esterilizaveltrocar";
            this.setLoading(LOADING_NAME);
            try {
                await api.put(`/equipamentos/esterelizavel/${fields.id}/`, fields);
                this.$toast.success('Esterilizável Trocado com Sucesso')
            } catch (error) {
                console.error(`Erro ao atualizar o esterilizável ${fields.id}:`, error);
                this.$toast.error(  `Erro ao atualizar o item ${fields.id}: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar(); // Atualiza a tabela
            }
        },
       async updateEsterilizavelRetirada(fields) {
            const LOADING_NAME = "put:esterilizaveltrocar";
            this.setLoading(LOADING_NAME);
            try {
                await api.put(`/equipamentos/esterelizavel/${fields.id}/`, fields);
                this.$toast.success('Esterilizável Trocado com Sucesso')
            } catch (error) {
                console.error(`Erro ao atualizar o esterilizável ${fields.id}:`, error);
                this.$toast.error(  `Erro ao atualizar o item ${fields.id}: ${error.message}`);
            } finally {
                this.setLoading(LOADING_NAME, true);
                this.atualizar(); // Atualiza a tabela
            }
        },
       async updateEsterilizavelReceber(fields) {
            const LOADING_NAME = "put:esterilizavelreceber";
            this.setLoading(LOADING_NAME);

            try {
                // Itera sobre cada item no array `fields` e envia individualmente
                for (const field of fields) {
                    try {
                        await api.post(`/equipamentos/esterelizavels/${field.id}/`, {
                            disponibilidade: field.disponibilidade,
                        });
                        console.log(`Esterilizável ${field.id} atualizado com sucesso.`);
                    } catch (error) {
                        console.error(`Erro ao atualizar o esterilizável ${field.id}:`, error);
                        this.$toast.error(
                            `Erro ao atualizar o item ${field.id}: ${error.message}`
                        );
                    }
                }
                this.$toast.success("Todos os itens foram processados com sucesso!");
                this.atualizar(); // Atualiza a tabela
            } catch (error) {
                console.error("Erro geral ao atualizar os itens:", error);
                this.$toast.error("Erro ao processar alguns itens.");
            } finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
        async createEsterilizacao(fields) {
            const LOADING_NAME = "post:esterilizacao";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await api.post(`/equipamentos/esterilizacao_criar/`, fields);
                // this.getEsterilizacao()
                this.dialog.create = false
                this.$toast.success('Esterilização criada com sucesso!!')
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
    },
    computed: {
        headersWithValidadeInfo() {
            // Clona o array de headers para não modificar o original
            const updatedHeaders = [...this.headers];

            // Encontra o índice do cabeçalho da validade
            const validadeIndex = updatedHeaders.findIndex(
                header => header.value === "data_validade_esterilizacao"
            );

            // Atualiza o texto apenas se houver itens vencidos
            if (validadeIndex !== -1 && this.validade > 0) {
                updatedHeaders[validadeIndex] = {
                    ...updatedHeaders[validadeIndex],
                    text: `Data da Validade Esterilização ( ${this.validade} vencido(s) )`
                };
            }

            return updatedHeaders;
        }
},
   mounted() {
    this.carregarDadosIniciais();
    
   }

}
</script>

<style scoped>
.badge-red {
  position: absolute;
  top: -8px; /* Ajuste fino da posição vertical */
  right: -8px; /* Ajuste fino da posição horizontal */
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.mr-2 {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

</style>


