<template >
  <v-form @submit.prevent="localOnSubmit" ref="formRef" >
    <v-row >
      <v-col cols="6">
        <v-autocomplete dense :loading="isLoadingPacientes" v-model="pacienteSelecionado" :items="resumoPacientes" item-value="id" item-text="nome" outlined clearable label="Pacientes" @change="updatePacienteData" ></v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data de Solicitação da Retirada" disabled v-model="dataRequisicao" type="date"  ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field dense outlined label="Data Presvista de  Retirada" disabled v-model="dataPresvista" type="date"  ></v-text-field>
      </v-col>
    </v-row>

    <v-row dense>
      <v-card-title class="blod_color">Itens Requisitados a Retirada:</v-card-title>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table dense :loading="isLoadingPacientes" :headers="ItemPacientelHeaders" :items="filterPaciente" item-value="id" show-select v-model="selectedPacienteItems" class="elevation-1" :items-per-page="5"  >
        <template v-slot:[`item.esterilizavel`]="{ item }">
          {{ item.esterilizavel?.nome }}
        </template>
        <template v-slot:[`item.quantidade`]="{ item }">
          {{ item.quantidade }}
        </template>
    </v-data-table>
      </v-col>
    </v-row>
    <v-row dense >
      <v-col cols="3">
        <v-autocomplete :loading="isLoadingDisponibilidade" dense label="Disponibilidade" v-model="currentItem.disponibilidade" outlined  :items="disponibilidade" item-text="nome" item-value="id"/>
      </v-col>
    </v-row>

    <v-row class="justify-end mt-0" dense>
      <v-btn class="mr-4 elevation-0" text @click="atualizar" :loading="isLoadingDados">
        <v-icon left>mdi-refresh</v-icon> Atualizar
      </v-btn>
      <v-col class="text-end">
        <v-tooltip top :disabled="isSaveEnabled">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton 
                :loading="loading" 
                color="success"
                :disabled="!isSaveEnabled"
                :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"
              >
                Salvar
              </ConfirmButton>
            </span>
          </template>
          <span>Selecione pelo menos um item e uma disponibilidade para salvar</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../http';
import UtilsFunc from '../../../service/utilsFunc';
import ConfirmButton from '../../ConfirmButton.vue';

export default {
  name: "CriarEsterilizacao",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      ativo: true,
      isLoadingPacientes: false,
      isLoadingDisponibilidade: false,
      pacientes: [],
      resumoPacientes: [],
      pacienteSelecionado: null,
      dataRequisicao: "",
      dataPresvista: "",
      dataImplantacao: "",
      selectedPacienteItems: [],
      selectedEsterelizaveis: [],
      filterPaciente: [], 
      disponibilidade: [],
      isLoadingDados: false,
      ItemPacientelHeaders: [
        { text: "Esterilizável", value: "esterilizavel" },
        { text: "Quantidade Solicitada", value: "quantidade" },
      ],
    });
  },
  components: { ConfirmButton },
  methods: {
   async getPacientes() {
      this.isLoadingPacientes = true;
      try {
        const response = await api("pacientes/esterilizaveis/");
        // Filtra primeiro os registros relevantes
        this.pacientes = response.data.filter(item => 
          item.data_solicitacao_retirada !== null && 
          item.esterilizavel &&
          item.ativo === true
        );
        
        // Agrupamento mantendo esterilizáveis separados
        const agrupamento = this.pacientes.reduce((acc, atual) => {
          const dataReq = atual.data_solicitacao_retirada.split('T')[0];
          const chave = `${atual.paciente.id}-${dataReq}`;
          
          if (!acc[chave]) {
            const data = new Date(atual.data_solicitacao_retirada);
            data.setDate(data.getDate() + 1);
            const dataFormatada = data.toLocaleDateString('pt-BR');
            
            acc[chave] = {
              id: chave,
              pacienteId: atual.paciente.id,
              nome: `ID: ${atual.paciente.id} - Nome: ${atual.paciente.nome} - Req (${dataFormatada})`,
              esterilizavel: [{
                id: atual.id,
                esterilizavel: {
                  nome: atual.esterilizavel.nome.nome // Ajuste aqui para pegar o nome corretamente
                },
                quantidade: 1
              }],
              data_solicitacao_retirada: atual.data_solicitacao_retirada,
              data_prevista_solicitacao_retirada: atual.data_prevista_solicitacao_retirada,
              data_prevista_implantacao: atual.data_prevista_implantacao
            };
          } else {
            acc[chave].esterilizavel.push({
              id: atual.id,
              esterilizavel: {
                nome: atual.esterilizavel.nome.nome // Ajuste aqui também
              },
              quantidade: 1
            });
          }
          return acc;
        }, {});
        // Converte para array final
        this.resumoPacientes = Object.values(agrupamento)
          .map(item => ({
            id: item.id,
            pacienteId: item.pacienteId,
            nome: item.nome,
            esterilizavel: item.esterilizavel,
            data_solicitacao_retirada: item.data_solicitacao_retirada,
            data_prevista_solicitacao_retirada: item.data_prevista_solicitacao_retirada,
            data_prevista_implantacao: item.data_prevista_implantacao
          }))
          .sort((a, b) => {
            const dataA = new Date(a.data_solicitacao_retirada);
            const dataB = new Date(b.data_solicitacao_retirada);
            return dataA - dataB;
          });

      } catch (error) {
        this.$toast.error(`Erro ao carregar pacientes. ${error}`);
      } finally {
        this.isLoadingPacientes = false;
      }
    },
    updatePacienteData() {
      this.selectedPacienteItems = [];
      
      const paciente = this.resumoPacientes.find(
        (p) => p.id === this.pacienteSelecionado
      );
      
      if (paciente) {
        this.dataRequisicao = paciente.data_solicitacao_retirada || "";
        this.dataPresvista = paciente.data_prevista_solicitacao_retirada || "";
        
        this.filterPaciente = paciente.esterilizavel.map(item => ({
          id: item.id,
          esterilizavel: {
            nome: item.esterilizavel.nome // Mantendo a estrutura consistente
          },
          quantidade: item.quantidade
        }));
      } else {
        this.dataRequisicao = "";
        this.dataPresvista = "";
        this.filterPaciente = [];
      }
    },
    async getDisponibilidade() {
      this.isLoadingDisponibilidade = true;
      try {
        const { data } = await api.get(`/uteis/disponibilidades/`);
        this.disponibilidade = data.filter(item => item.ativo && ![1, 2, 3, 4, 5, 7].includes(item.id))
        // console.log(this.disponibilidade)
      } catch (error) {
        this.$toast.error(`Desculpe. Algo deu errado. ${error}`);
      } finally {
        this.isLoadingDisponibilidade = false;
      }
    },
    atualizar() {
      this.getPacientes()
      this.getDisponibilidade()
    },
  },
  computed: {
    isSaveEnabled() {
      // Verifica se tem pelo menos um item selecionado e uma disponibilidade selecionada
      return (
        this.selectedPacienteItems.length > 0 && 
        this.currentItem?.disponibilidade !== undefined && 
        this.currentItem?.disponibilidade !== null
      );
    }
  },
  mounted() {
    this.getDisponibilidade()   
    this.getPacientes();
  },
};
</script>
