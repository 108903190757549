<template>
    <div class="pa-4" >
        <v-form @submit.prevent="localOnSubmit" ref="formRef">
            <div class="grid-container">
                <!-- Dados do item -->
                <template v-for="(item, index) in items">
                    <v-row dense :key="index" >
                        <v-col cols="2">
                            <v-autocomplete :loading="isLoadingEsterilizavel" label="Esterilizável " dense :items="esterilizavel" item-text="nome" item-value="id" return-object v-model="item.nome"  outlined :rules="requiredField" > </v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-autocomplete :loading="isLoadingDepositos" :items="depositos" item-text="nome" item-value="id" return-object label="Deposito" v-model="item.deposito" dense  outlined :rules="requiredField" > </v-autocomplete>
                        </v-col>
                        <v-col cols="1">
                            <vuetify-money label="Valor Und." dense v-model="item.valor" outlined > </vuetify-money>
                        </v-col>
                        <v-col cols="1">
                            <v-text-field dense label="Desconto %" v-model="item.desconto" outlined type="number"  > </v-text-field>
                        </v-col>
                        <v-col cols="1">
                            <vuetify-money label="Valor Und. Final" dense :value="calculateFinalValue(item)" outlined disabled > </vuetify-money>
                        </v-col>
                        <v-col cols="1">
                            <v-text-field dense label="Qtd" v-model="item.quantidade"  outlined type="number"  > </v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-text-field dense label="Data da Validade" v-model="item.data_validade_esterilizacao"  outlined type="date"  > </v-text-field>
                        </v-col>
                        <v-col>
                            <v-btn color="success" class="elevation-0" @click="addItem" > <v-icon left>mdi-plus</v-icon> Itens </v-btn>
                        </v-col>
                        <v-col v-if="index > 0">
                            <v-btn 
                                color="error" 
                                class="elevation-0" 
                                @click="removeItem(index)"
                            >
                                <v-icon left>mdi-delete</v-icon> Remover
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
                    <!-- Dados da Nata fiscal  -->
                <v-row dense >
                    <v-col cols="3">
                        <v-autocomplete :loading="isLoadingAquisicoes" label="Nº da Nota Fiscal / Recibo" name="tipo" :items="aquisicao" item-text="nota_fiscal" item-value="id" return-object dense  v-model="currentItem.aquisicao" @change="getFornecedor"  outlined :rules="requiredField"  >  </v-autocomplete>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field dense name="fornecedor" label="Fornecedor" v-model="fornecedor" outlined disabled></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-btn color="success" class="elevation-0" @click="dialog.create = true"> <v-icon left>mdi-plus</v-icon> Nova NF </v-btn>
                    </v-col>
                </v-row>
            </div>
            <!-- Botõa de Salvar -->
            <v-row dense>
                <v-col class="text-end mx-4">
                    <v-btn class="mr-4" color="red" text @click="clear()"> Limpar </v-btn>
                    <v-tooltip top :disabled="isSaveEnabled">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs">
                        <ConfirmButton 
                            :loading="loading" 
                            color="success"
                            :disabled="!isSaveEnabled"
                            :style="{ opacity: isSaveEnabled ? 1 : 0.5 }"
                        >
                            Salvar
                        </ConfirmButton>
                        </span>
                    </template>
                    <span>
                        Preencha todos os campos obrigatórios, selecione uma nota fiscal e verifique se todos os itens têm valores e datas válidos
                    </span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-form>
    <!-- Criação de Nova NF -->
       <v-dialog v-model="dialog.create" v-if="dialog.create" max-width="1100">
            <v-card>
                <v-card-title class="blod_color">
                Nova Nota Fiscal:
                <v-spacer></v-spacer>
                <v-btn icon @click="dialog.create = false">
                    <v-icon>
                    mdi-close
                    </v-icon>
                </v-btn>
                </v-card-title>
                <v-card-text>
                <NotaFiscalCriar :currentItem="newItem" :loading="isLoading('post:notafiscal')" :onSubmit="createNotaFiscal" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import api from '@/http'
import UtilsFunc from '../../../service/utilsFunc'
import ConfirmButton from '../../ConfirmButton.vue'
import NotaFiscalCriar from '../notaFiscal/NotaFiscalCriar.vue';


const { withCRUDUtils } = UtilsFunc

export default {
    name: "CadastroControle",
    components: { ConfirmButton, NotaFiscalCriar },
    data: () => withCRUDUtils ({
        items: [],
        newItem: {ativo: true},
        esterilizavel: [],
        aquisicao: [],
        depositos: [],
        fornecedor: '',
        isLoadingEsterilizavel: false, // Carregando esterilizável
        isLoadingDepositos: false, // Carregando depósitos
        isLoadingAquisicoes: false, // Carregando aquisições
        requiredField: [e => (e ? true : 'Obrigatório')],
        validateNovo: [ e => (e === 0 || e === 1) || 'Selecione uma opção válida',],
    }),
    props: {
        onSubmit: Function,
        loading: Boolean,
        formRef: Object,
        currentItem: { 
            type: Object, 
            default: () => ({
                ativo: true,
            })
        },
    },
   
    methods: {
       async getAquisicoes() {
            this.isLoadingAquisicoes = true;
            try {
                const response = await api.get('equipamentos/aquisicao/');
                this.aquisicao = response.data.filter(item => item.ativo);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoadingAquisicoes = false;
            }
        },
        async getNomeEsterilizavel() {
            this.isLoadingEsterilizavel = true;
            try {
                const response = await api.get('equipamentos/nomeesterelizavels/');
                this.esterilizavel = response.data.filter(item => item.ativo);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoadingEsterilizavel = false;
            }
        },
        async getDepositos() {
            this.isLoadingDepositos = true;
            try {
                const response = await api.get('uteis/deposito/');
                this.depositos = response.data.filter(item => item.ativo);
            } catch (error) {
                console.error(error);
            } finally {
                this.isLoadingDepositos = false;
            }
        },
        getFornecedor(aquisicao) {
            if(aquisicao) {
                this.fornecedor = aquisicao.fornecedor.nome_fantasia
                this.nota_fiscal = aquisicao.nota_fiscal
            } else {
                this.fornecedor = ''
                this.nota_fiscal = ''
            }
        },
        localOnSubmit() {
            const validation = this.$refs.formRef.validate();
            if (!validation) return;

            // Cria um array com todos os itens replicados pela quantidade
            const Itens = this.items.flatMap(item => {
                return Array.from({ length: item.quantidade || 1 }).map(() => ({
                    nome: item.nome.id,
                    deposito: item.deposito.id,
                    valor: item.valor,
                    desconto: item.desconto,
                    valor_final: item.valor - (item.valor * (item.desconto || 0) / 100),
                    aquisicao: this.currentItem.aquisicao.id,
                    data_validade_esterilizacao: item.data_validade_esterilizacao,
                    ativo: true,
                    disponibilidade: 10,
                }));
            });

            for (const fields of Itens) {
                this.onSubmit(fields);
            }

        },

        async createNotaFiscal(fields) {
            const LOADING_NAME = "post:notafiscal";
            this.setLoading(LOADING_NAME);
            try {
                // console.log(fields)
                await api.post(`/equipamentos/aquisicao_criar/`, fields);
                this.getAquisicoes()
                this.dialog.create = false
                this.$toast.success('Nota Fiscal criada com sucesso!')
            }
            catch (error) {
                this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
            }
            finally {
                this.setLoading(LOADING_NAME, true);
            }
        },
        addItem() {
            // Adiciona um novo item com valores padrão
            this.items.push({
                nome: null,
                deposito: null,
                valor: 0,
                desconto: 0,
                quantidade: 1,
            });
        },
        calculateFinalValue(item) {
            const valor = parseFloat(item.valor || 0);
            const desconto = parseFloat(item.desconto || 0) / 100;
            return valor - (valor * desconto);
        },
        removeItem(index) {
            // Remove o item do array
            this.items.splice(index, 1);
        },
        clear() {
   
           this.items.forEach(item => {
                item.quantidade = 1; // Valor padrão
                item.nome = null;
                item.deposito = null;
                item.valor = 0;
                item.desconto = 0;
                this.removeItem()
            });
            this.removeItem()
        },
    },
    computed: {
        valorFinal() {
            const valor = parseFloat(this.currentItem.valor || 0);
            const desconto = parseFloat(this.currentItem.desconto || 0) / 100;
            return valor - (valor * desconto);
        },
        isSaveEnabled() {
            // Verifica se tem nota fiscal selecionada
            if (!this.currentItem.aquisicao) {
            return false;
            }

            // Verifica se tem pelo menos um item na lista
            if (!this.items.length) {
            return false;
            }

            // Verifica se todos os itens têm os campos obrigatórios preenchidos
            const todosItensSaoValidos = this.items.every(item => {
            // Verifica campos obrigatórios de cada item
            if (!item.nome || !item.deposito) {
                return false;
            }

            // Verifica se quantidade é válida
            if (!item.quantidade || item.quantidade < 1) {
                return false;
            }
            
            // Verifica se data de validade foi preenchida
            if (!item.data_validade_esterilizacao) {
                return false;
            }

            // Verifica se a data de validade é futura
            const dataValidade = new Date(item.data_validade_esterilizacao);
            const hoje = new Date();
            hoje.setHours(0, 0, 0, 0);
            if (dataValidade <= hoje) {
                return false;
            }

            return true;
            });

            return todosItensSaoValidos;
        }
    },
    mounted() {
        this.clear();
        this.addItem();
        this.currentItem.ativo = Number(this.currentItem.ativo)
        this.getAquisicoes(),
        this.getNomeEsterilizavel()
        this.getDepositos()
    },
}
</script>
